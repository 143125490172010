import { callApi } from '@/services/WebApi';


export const getPublicById = (formId) => {
  return callApi('GET', `forms/public/${formId}`)
}

export const getById = (formId) => {
  return callApi('GET', `forms/${formId}`)
}

export const withdrawById = (formId) => {
  return callApi('POST', `forms/withdraw/${formId}`)
}

//############## digital-finance-application ############## 

export const getDigitalFinanceApplications = () => {
  //const data = { formName: "digital-finance-application"};
  return callApi('GET', `forms/user/digital-finance-application`)
};

export const submitDigitalFinanceApplication = (data) => {
  //console.log("submitDigitalFinanceApplication", data);
  return callApi('POST', `forms/digital-finance-application`, data)
};

export const submitDigitalFinanceApplicationDraft = (data) => {
  //console.log("submitDigitalFinanceApplication", data);
  return callApi('PUT', `forms/digital-finance-application`, data)
};

export const saveDigitalFinanceApplicationResumeEmail = (data) => {
  return callApi('POST', `forms/public/digital-finance-application-resume-email`, data)
}

export const submitDigitalFinanceApplicationPublic = (data) => {
  console.log("POST submitDigitalFinanceApplicationPublic", data);
  return callApi('POST', `forms/public/digital-finance-application`, data)
};

export const submitDigitalFinanceApplicationPublicDraft = (data) => {
  console.log("PUT submitDigitalFinanceApplicationPublicDraft", data);
  return callApi('PUT', `forms/public/digital-finance-application`, data)
}


//############## corpsec-reg-new-co ############## 

export const getCorpsecRegNewCos = () => {
  //const data = { formName: "corpsec-register-new-company"};
  return callApi('GET', `forms/user/corpsec-register-new-company`)
};

export const submitCorpsecRegNewCo = (data) => {
  //console.log("submitDigitalFinanceApplication", data);
  return callApi('POST', `forms/corpsec-reg-new-co`, data)
};

export const submitCorpsecRegNewCoDraft = (data) => {
  //console.log("submitDigitalFinanceApplication", data);
  return callApi('PUT', `forms/corpsec-reg-new-co`, data)
};

export const saveCorpsecRegNewCoResumeEmail = (data) => {
  return callApi('POST', `forms/public/corpsec-reg-new-co-resume-email`, data)
}

export const submitCorpsecRegNewCoPublic = (data) => {
  console.log("POST submitDigitalFinanceApplicationPublic", data);
  return callApi('POST', `forms/public/corpsec-reg-new-co`, data)
};

export const submitCorpsecRegNewCoPublicDraft = (data) => {
  console.log("PUT submitDigitalFinanceApplicationPublicDraft", data);
  return callApi('PUT', `forms/public/corpsec-reg-new-co`, data)
}


//############## corpsec-transfer-in-co ############## 

export const getCorpsecTransferInCos = () => {
  //const data = { formName: "corpsec-transfer-in-company"};
  return callApi('GET', `forms/user/corpsec-transfer-in-company`)
};

export const submitCorpsecTransferInCo = (data) => {
  //console.log("submitCorpsecTransferInCo", data);
  return callApi('POST', `forms/corpsec-transfer-in-co`, data)
};

export const submitCorpsecTransferInCoDraft = (data) => {
  //console.log("submitCorpsecTransferInCoDraft", data);
  return callApi('PUT', `forms/corpsec-transfer-in-co`, data)
};

export const saveCorpsecTransferInCoResumeEmail = (data) => {
  return callApi('POST', `forms/public/corpsec-transfer-in-co-resume-email`, data)
}

export const submitCorpsecTransferInCoPublic = (data) => {
  console.log("POST submitCorpsecTransferInCoPublicDraft", data);
  return callApi('POST', `forms/public/corpsec-transfer-in-co`, data)
};

export const submitCorpsecTransferInCoPublicDraft = (data) => {
  console.log("PUT submitCorpsecTransferInCoPublicDraft", data);
  return callApi('PUT', `forms/public/corpsec-transfer-in-co`, data)
}


//############## corpsec-reg-new-co ############## 

export const getCorpsecRegNewCoCombines = () => {
  //const data = { formName: "corpsec-register-new-company"};
  return callApi('GET', `forms/user/corpsec-register-new-company-combine`)
};

export const submitCorpsecRegNewCoCombine = (data) => {
  //console.log("submitDigitalFinanceApplication", data);
  return callApi('POST', `forms/corpsec-reg-new-co-combine`, data)
};

export const submitCorpsecRegNewCoCombineDraft = (data) => {
  //console.log("submitDigitalFinanceApplication", data);
  return callApi('PUT', `forms/corpsec-reg-new-co-combine`, data)
};

export const saveCorpsecRegNewCoCombineResumeEmail = (data) => {
  return callApi('POST', `forms/public/corpsec-reg-new-co-combine-resume-email`, data)
}

export const submitCorpsecRegNewCoCombinePublic = (data) => {
  console.log("POST submitDigitalFinanceApplicationPublic", data);
  return callApi('POST', `forms/public/corpsec-reg-new-co-combine`, data)
};

export const submitCorpsecRegNewCoCombinePublicDraft = (data) => {
  console.log("PUT submitDigitalFinanceApplicationPublicDraft", data);
  return callApi('PUT', `forms/public/corpsec-reg-new-co-combine`, data)
}

//############## corpsec-reg-new-co-msia ############## 
export const getCorpsecRegNewCoCombinesMY = () => {
  //const data = { formName: "corpsec-register-new-company"};
  return callApi('GET', `forms/user/corpsec-register-new-company-combine-my`)
};

export const submitCorpsecRegNewCoCombineMY = (data) => {
  //console.log("submitDigitalFinanceApplication", data);
  return callApi('POST', `forms/corpsec-reg-new-co-combine-my`, data)
};

export const submitCorpsecRegNewCoCombineMYDraft = (data) => {
  //console.log("submitDigitalFinanceApplication", data);
  return callApi('PUT', `forms/corpsec-reg-new-co-combine-my`, data)
};

export const saveCorpsecRegNewCoCombineMYResumeEmail = (data) => {
  return callApi('POST', `forms/public/corpsec-reg-new-co-combine-my-resume-email`, data)
}

export const submitCorpsecRegNewCoCombineMYPublic = (data) => {
  return callApi('POST', `forms/public/corpsec-reg-new-co-combine-my`, data)
};

export const submitCorpsecRegNewCoCombineMYPublicDraft = (data) => {
  return callApi('PUT', `forms/public/corpsec-reg-new-co-combine-my`, data)
}



//############## central-contact ############## 

export const submitCentralContact = (data) => {
  //console.log("submitCentralContact", data);
  return callApi('POST', `forms/public/central-contact`, data)
};