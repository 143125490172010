<script setup>
import { ref, onMounted, watch, onBeforeMount } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router';

const route = useRoute();
const home = ref({
    icon: 'pi pi-home',
    route: '/dashboard'
});
const items = ref([]);

//console.log("route.params", route.params);
//onBeforeMount(() => { rebuildBreadcrumb(); });
onMounted(() => {
  rebuildBreadcrumb();
});
watch(
  () => route.fullPath,
  async () => {
    rebuildBreadcrumb();
  }
);

const rebuildBreadcrumb = () => {
  const arr = [];
  for (var i in route.matched) {
    const r = route.matched[i];
    if (r.meta.breadcrumb) {
      const b = { ...r.meta.breadcrumb }; //clone obj
      for (var key in route.params) {
        b.label = b.label.replace(':id', route.params[key]);
        b.route = b.to.replace(':id', route.params[key]);
        
      }
      arr.push(b);
    }
  }
  items.value = arr;
};

// const items = ref([
//     {label: 'Digital Identity', to: '/digital-identity',},
// ]);
</script>

<template>
  <div class="flex justify-content-start">
    <!-- <Breadcrumb :home="home" :model="items" /> -->
    <Breadcrumb :home="home" :model="items">
      <template #item="{ item, props }">
          <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
              <a :href="href" v-bind="props.action" @click="navigate">
                  <span :class="[item.icon, 'text-color']" />
                  <span class="text-primary font-semibold">{{ item.label }}</span>
              </a>
          </router-link>
          <a v-else :href="item.url" :target="item.target" v-bind="props.action">
              <span class="text-color">{{ item.label }}</span>
          </a>
      </template>
    </Breadcrumb>
  </div>
</template>

<style scoped lang="scss">
.p-breadcrumb {
  padding: 0;
  background: transparent;
  border: 0;
  min-height: 20px;
}
</style>
