<template>
	<div class="logo-holder">
		<a href="/"><i class="ico logo-m360 logo block" :class="logoStyle"></i></a>
	</div>
</template>

<script setup>
import {computed} from 'vue';

  const props = defineProps({
    size: {type:String}
  })

  const logoStyle = computed(() => ({
    'logo-big' : props.size === 'big'
  }))
</script>

<style scoped lang="scss">
.logo-holder {
  .logo {
    width: 100px;
    height: 36.98px;

    &.logo-big {
      width: 140px;
      height: 51.778px;
    }
  }
}
</style>
