<script setup>
import { onBeforeMount, onMounted, toRaw, toRef, computed, watch, ref } from "vue";
import env from "@/helpers/Environment";
import _ from "lodash";
import { useRouter, useRoute } from 'vue-router'
import Cookies from 'js-cookie';
import Constant from "@/helpers/Constant";
import Toast from 'primevue/toast';
import ConfirmDialog from 'primevue/confirmdialog';
import { markRaw, defineAsyncComponent, shallowRef } from 'vue';
import { useDialog } from 'primevue/usedialog';

import {useStoreAccount} from '@/stores/store.account';
import {useStoreSite} from '@/stores/store.site';
import { useStoreCompany } from "./stores/store.company";
const route = useRoute()
onMounted(()=>{
  //console.log("env", env)
  window.onpopstate = event => {

    const isLoggedIn = storeAccount.isLoggedIn;
    // console.log("event", event)
    // console.log("isLoggedIn", isLoggedIn)
    // console.log("route", route.path)

    // non existing path?
    if (!isLoggedIn && route.path == "/auth/login") {
    // if (!isLoggedIn) {
         window.location.href = "/"; 
    }
  };
  
})
const storeAccount = useStoreAccount();
const storeSite = useStoreSite();
const storeCompany = useStoreCompany();

const router = useRouter()
const stageEnv = env.STAGE_ENV;
const cTitle = computed(() => toRaw(storeSite.title));
const cSiteLayout = computed(() => toRaw(storeSite.layoutView));
const cIsMobile = computed(() => storeSite.isMobile);
const cIsNoneLayout = computed(() => storeSite.layout == 'none');

let runOnceOnChangeFlag = false;
const debounceResizeWindow = _.debounce(() => {
      let flag = storeSite.checkIsMobile();
      //let flag = storeSite.checkIsMediumScreen();
      if (runOnceOnChangeFlag != flag) {
        runOnceOnChangeFlag = flag;
        if (flag) {
          storeSite.resetFlag("mobile");
        } else {
          storeSite.resetFlag("screen");
        }
      }
      //console.log("debounceResizeWindow", flag)
    }, 100);
window.addEventListener("resize", debounceResizeWindow);
debounceResizeWindow();

const updateLoginStatus = (e) => {
  // console.log("store account is logged in", storeAccount.user?.isLoggedIn)
  // console.log("store was logged in ==========>" ,storeSite.wasLoggedIn)
  // console.log("cookie was logged in ==========>" ,Cookies.get("was_logged_in"))
  // console.log("iframe message ==========>" , e.data)

  if (e?.data === 'false' && Cookies.get(Constant.COOKIE_WAS_LOGGED_IN)) {
    // console.log("logout")
    storeAccount.logout();
    Cookies.remove(Constant.COOKIE_WAS_LOGGED_IN)
    window.location.reload()
  }
};
window.addEventListener("message", updateLoginStatus)

onBeforeMount(async () => {
  // loynote: set title to empty so teleport will not append to it.
  document.title = "";
});


</script>

<template>
  
  <!-- <div class="debug absolute text-xs pointer-events-none text-pink-500">
    <pre><code><small>{{ storeSite  }}</small></code></pre>
    <pre><code><small>{{ storeAccount  }}</small></code></pre>
  </div> -->


<div class="ribbon" v-if="stageEnv != 'prod' && !cIsNoneLayout">{{stageEnv}}</div>

<Toast />

<DynamicDialog />

<ConfirmDialog></ConfirmDialog>
<!-- <ConfirmDialog class="confirmDialog-custom">
  <template #message="slotProps">
    <div class="flex justify-content-center text-left">
        <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
        <p class="pl-2">{{ slotProps.message.message }}</p>
    </div>
  </template>
</ConfirmDialog> -->


<teleport to="head title">{{ cTitle }}</teleport>

<div :class="['wrapper', cIsMobile?'mobile':'']">
<!-- {{store.state.site.mobile}} -->
  <component :is="cSiteLayout" />
</div>
<iframe id="iframe-session" :src="`${env.OIDC_URL}/check-session`" style="display:none"></iframe>
</template>


<style lang="scss">

.confirmDialog-custom {
  width: 300px;
}

@media screen and (min-width:820px) {
  .confirmDialog-custom {
    width: 500px;
  }
}

@media screen and (min-width:$break-large) {
  .confirmDialog-custom {
    width: 450px;
  }
}


.debug {
  color: red;
  position:absolute;
  width:800px;
  top: 50px;
}

.wrapper {
  width: 100%;
  height: 100%;
}
</style>