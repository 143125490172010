<template>
    <div class="relative">
        <div class="spinner-custom-2"></div>
        <img src="@/assets/icons/logo-m360.png" class=" spinner-img" width="100"/>
    </div>

</template>

<style scoped lang="scss">

.spinner-img {
	position: absolute;
    top: 55px;
    left: 20px;

	// top: 50%;
	// left: 50%;
	// width: 200px;
	// height: 200px;
	// background-image: url("@/assets/icons/logo-m360.png");
	// background-position: center;
	// background-repeat: no-repeat;
	// background-size: 50%;
}
.spinner-custom-2 {
	border: 8px solid white;
	background-color: white;
	border-radius: 50%;
	border-top: 8px solid #C6C6C6;
	// border-right: 8px solid #619BEE;
	// border-bottom: 8px solid #96BFF9;
	// border-left: 8px solid #C9E0FF;

	width: 140px;
	height: 140px;

	-webkit-animation: spin 1.5s linear infinite;
	animation: spin 1.5s linear infinite;
}
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
