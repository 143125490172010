<script setup>
	import {ref, computed} from 'vue';

	const props = defineProps({
		size:{type:String, default:''},
		loaderStyle: {type:Object, default:{}},
		// width:{type:String, default: '65px'},
		// height:{type:String, default: '65px'},
		size:{type:String, default:''}
	})

	const loaderClass = computed(() => ({
		'loader-big': props.size == 'big'
	}))

	const size = computed(() => {
		if (props.size == 'small') {
			return {width:'15px', height: '15px'}
		} else if (props.size == 'medium') {
			return {width:'25px', height: '25px'}
		} else {
			return {width:'65px', height: '65px'}
		}
	})

</script>

<template>
	<!-- <div class="loader" :class="loaderClass" :style="loaderStyle"></div> -->
	<svg class="spinner" :width="size.width" :height="size.height" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
   <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
</svg>
</template>

<style scoped lang="scss">
// .loader {
// 	border: 2px solid #f3f3f3;
// 	border-radius: 50%;
// 	border-top: 5px solid #FFE79D;
// 	width: 15px;
// 	height: 15px;
// 	-webkit-animation: spin 0.5s linear infinite; /* Safari */
// 	animation: spin 0.5s linear infinite;

// 	&.loader-big {
// 		width: 32px;
// 		height: 32px;
// 	}
// }

// /* Safari */
// @-webkit-keyframes spin {
// 	0% {
// 		-webkit-transform: rotate(0deg);
// 	}
// 	100% {
// 		-webkit-transform: rotate(360deg);
// 	}
// }

// @keyframes spin {
// 	0% {
// 		transform: rotate(0deg);
// 	}
// 	100% {
// 		transform: rotate(360deg);
// 	}
// }

$offset: 187;
$duration: 1s;
.spinner {
  animation: rotator $duration linear infinite;
}

@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation:
    dash $duration ease-in-out infinite, 
    colors ($duration*4) ease-in-out infinite;
}

@keyframes colors {
// 	0% { stroke: #4285F4; }
// 	25% { stroke: #DE3E35; }
// 	50% { stroke: #F7C223; }
// 	75% { stroke: #1B9A59; }
//   100% { stroke: #4285F4; }
// 0% {stroke: #A31D22}
// 100% {stroke: #FBCE07}
0% {stroke: #aaa}
100% {stroke: #aaa}
}

@keyframes dash {
 0% { stroke-dashoffset: $offset; }
 50% {
   stroke-dashoffset: calc($offset / 4);
   transform:rotate(135deg);
 }
 100% {
   stroke-dashoffset: $offset;
   transform:rotate(450deg);
 }
}
</style>
