import { callApi, callApiUrl } from '@/services/WebApi';
import axios from 'axios';
import env from '@/helpers/Environment';

export const getApiVersion = () => {
  return callApiUrl('GET', env.API_URL);
}

export const getUploadPresignedUrl = (mt) => {
  return callApi('GET', 'site/get-upload-presigned-url/'+mt);
}

export const getUploadPresignedUrlExt = (endpoint, data) => {
  let query = new URLSearchParams(data).toString();
  return callApiUrl('GET', endpoint + "/" + 'site/get-presigned-url?'+query)
  // return callApiUrl('GET', endpoint + "/" + 'site/get-presigned-url', data);
}

export const uploadS3 = async (data, mimeType="image/jpeg") => {
  //change image/jpeg to image-jpeg
  const mt = mimeType.replace("/","-");
  const r = await getUploadPresignedUrl(mt);
  // //console.log("uploadS3", r.data);
  // const uploadUrl = r.data.uploadUrl + "fail";
  const { uploadUrl } = r.data;

  const headers = {
    //'Content-Type': 'image/jpeg',
    'Content-Type': mimeType,
    // 'Content-Length': data.size,
  };

  const cancelable = false;
  const withCredentials = false;
  try {
    await callApiUrl('PUT', uploadUrl, data, headers, cancelable, withCredentials);
    // console.log(upload)
    return r.data;
  } catch(e) {
    console.error(e)
    throw e;
  }
  
  
};

//todo: make dynamic endpoint
export const uploadS3Ext = async (data, mimeType="image/jpeg", endpoint) => {
  
  const r = await getUploadPresignedUrlExt(env.API_CORPSEC_URL, {
    mimeType:mimeType,
    fileName:data.name
  });
  
  // //console.log("uploadS3", r.data);
  // const uploadUrl = r.data.uploadUrl + "fail";
  const { uploadUrl } = r.data;

  const headers = {
    //'Content-Type': 'image/jpeg',
    'Content-Type': mimeType,
    // 'Content-Length': data.size,
  };

  const cancelable = false;
  const withCredentials = false;
  try {
    await callApiUrl('PUT', uploadUrl, data, headers, cancelable, withCredentials);
  } catch(e) {
    //console.log(e)
  }
  return r.data;
};

export const sendFeedback = (formData) => {
  return callApi('POST', 'site/send-feedback', formData);
}

/*
const getShuftiproLink = (data) => {
  return callApi('POST', `kyc/get-shuftipro-link`, data)
};
*/


// export const siteService = {
//   getApiVersion,
//   getUploadPresignedUrl,
//   uploadS3,
//   sendFeedback,
//   getShuftiproLink,
// };
