import  Joi from 'joi';
import _ from 'lodash';
import { joiIntlErrors } from '@/intl/joiIntlError.js'
import { useStoreSite } from "@/stores/store.site";

//const opts = { abortEarly:false, stripUnknown:true, messages:joiIntlErrors, errors: { label:"path" } };
const opts = { abortEarly:false, stripUnknown:true, messages:joiIntlErrors, errors: { label:false } };
export const customJoi = Joi.defaults((schema) => schema.options(opts));


export const getJoiData = (schema, data) => {
  const storeSite = useStoreSite();

  //console.log("getJoiData", data);
  let j = null;
  const localisedJoi = customJoi.defaults((schema) => {
    return schema.options({
      errors: { language: storeSite.locale }
    })
  })
  j = localisedJoi.object(schema);
  //console.log("getJoiData", storeSite.locale)

  const { value, error } = j.validate(data);
  // console.log("getJoiData", value, error);
  return { value, error };
};

export const getJoiDataAsync = async (schema, data) => {
  // //console.log("getJoiDataAsync", data);
  let value; let
    error = null;
  try {
    value = await customJoi.object(schema).validateAsync(data);
  } catch (err) {
    error = err;
  }
  return { value, error };
};

//export const customJoi2 = customJoi;

// loynote:: same func are found in backend /middlewares/errors.js

export const prepareJoiErrorArr = (err) => {
  const keys = _.map(err.details, 'context.key');
  const messages = _.map(err.details, 'message');
  // const data = _.map(err.details, 'data')
  console.log('prepareJoiErrorArr', err.details);
  const arr = keys.map((key, i) => ({ field: `${key}`, message: messages[i] }));
  return arr;

  /*
  const o = {}
  keys.map((key, i) => {o[key] = messages[i]});
  return o;
  */
};

export const prepareApiErrorArr = (err) => err.errArr;
