<script setup>
//This carousel is to use to pass in background image only

import "vue3-carousel/dist/carousel.css"
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel"

const props = defineProps({
  items:{type: Array, default:[]},
  id: {type:String, default: 'carousel'}
}) 

const breakpoints = {
	0: { itemsToShow: 1, snapAlign: "start" },
	500: { itemsToShow: 1, snapAlign: "start" },
	600: { itemsToShow: 1, snapAlign: "start" },
	768: { itemsToShow: 1.2, snapAlign: "start" },
	900: { itemsToShow: 1.4, snapAlign: "start" },
	1024: { itemsToShow: 1.6, snapAlign: "start" },
	1240: { itemsToShow: 2, snapAlign: "start" },
	1420: { itemsToShow: 2.7, snapAlign: "start" },
	1550: { itemsToShow: 2.9, snapAlign: "start" },
	1700: { itemsToShow: 3.5, snapAlign: "start" },
	// 1900: { itemsToShow: 3.9, snapAlign: "start" },
}
</script>

<template>
	<Carousel :id="id" snapAlign="start"  :breakpoints="breakpoints" :autoplay="0" mouse-drag>
		<Slide v-for="(item, index) in items"  :key="index">
			<!-- <Image v-if="item.img" :src="item.img" class="w-full" style="object-fit: contain" /> -->
			<div class="img-container" :style="{ 'background-image': 'url(' + item.img + ')' } ">
			</div>
		</Slide>

		<template #addons>
			<!-- <Pagination /> -->
			<Navigation />
		</template>

		<!-- <template #addons>
      <Navigation >
        <template #prev>
          <div class="carousel__prev"><i class="pi pi-angle-left"></i></div>
        </template>
        <template #next>
          <div class="carousel__next"><i class="pi pi-angle-right"></i></div>
        </template>
      </Navigation>

      <Pagination />
    </template> -->
	</Carousel>
</template>

<style scoped lang="scss">

.img-container{
	background-size: 100% 100% ;
	height: 112px;
	width: 373px;
}
</style>
