export const en = {
	"Start Registration": (ctx) => "Start Registration",
	"Register your business in Singapore": (ctx) => "Register your business in Singapore",
	"Enjoy hassle-free service and responsive support. Register your business 100% online with us today.": (ctx) =>
		"Enjoy hassle-free service and responsive support. Register your business 100% online with us today.",
	"Save Form Draft": (ctx) => "Save Form Draft",
	"This registration takes approximately 15 minutes. You may save your progress and resume your form application at your convenience.":
		(ctx) =>
			"This registration takes approximately 15 minutes. You may save your progress and resume your form application at your convenience.",
	"This registration takes approximately 15 minutes. Your progress will be saved and you may resume your form application at your convenience.":
		(ctx) =>
			"This registration takes approximately 15 minutes. Your progress will be saved and you may resume your form application at your convenience.",

	"Company Details": (ctx) => "Company Details",
	"Members / Shareholders Details": (ctx) => "Members / Shareholders Details",
	"Officers Details": (ctx) => "Officers Details",
	Constitution: (ctx) => "Constitution",
	"Financial Year End": (ctx) => "Financial Year End",
	Declaration: (ctx) => "Declaration",

	/* Form.CorpsecRegNewCoCombine - step 1 */
	"PROPOSED COMPANY NAME": (ctx) => "PROPOSED COMPANY NAME",
	"Please provide up to 3 options.": (ctx) => "Please provide up to 3 options.",
	"Company Name (1st choice)": (ctx) => "Company Name (1st choice)",
	"Company Name (2nd choice)": (ctx) => "Company Name (2nd choice)",
	"Company Name (3rd choice)": (ctx) => "Company Name (3rd choice)",
	"Company name subjects to approval by ACRA": (ctx) => "Company name subjects to approval by ACRA",
	"ISSUED & PAID UP CAPITAL": (ctx) => "ISSUED & PAID UP CAPITAL",
	"What is your base currency": (ctx) => "What is your base currency",
	"Base Currency": (ctx) => "Base Currency",
	"Please specify your issued & paid up capital.": (ctx) => "Please specify your issued & paid up capital.",
	"Capital Amount": (ctx) => "Capital Amount",
	"If the paid up capital is $5,000 & above (in any currency), to produce either the bank remittance advice or the cheque image for proof of payment by shareholder(s).":
		(ctx) =>
			"If the paid up capital is $5,000 & above (in any currency), to produce either the bank remittance advice or the cheque image for proof of payment by shareholder(s).",
	"MAIN PRINCIPAL ACTIVITIES": (ctx) => "MAIN PRINCIPAL ACTIVITIES",
	"Primary Activity": (ctx) => "Primary Activity",
	"Secondary Activity": (ctx) => "Secondary Activity",
	"REGISTERED ADDRESS": (ctx) => "REGISTERED ADDRESS",
	"Please state if the registered address is at": (ctx) => "Please state if the registered address is at",
	"The registered address is different.": (ctx) => "The registered address is different.",
	"Address 1": (ctx) => "Address 1",
	"Address 2": (ctx) => "Address 2",
	"Postal Code": (ctx) => "Postal Code",
	Next: (ctx) => "Next",

	/* Form.CorpsecRegNewCoCombine - step 2 */
	"State the names of two (2) Directors if member is a corporation. A common seal is required to be affixed in the constitution.":
		(ctx) =>
			"State the names of two (2) Directors if member is a corporation. A common seal is required to be affixed in the constitution.",
	"Add Member": (ctx) => "Add Member",
	"Name (as per NRIC/Passport/ACRA)": (ctx) => "Name (as per NRIC/Passport/ACRA)",
	"NRIC/Passport/ACRA No.": (ctx) => "NRIC/Passport/ACRA No.",
	"Date of Birth": (ctx) => "Date of Birth",
	"Mobile Number": (ctx) => "Mobile Number",
	"Residential Address": (ctx) => "Residential Address",
	"No of Ordinary Shares Held": (ctx) => "No of Ordinary Shares Held",
	Cancel: (ctx) => "Cancel",
	"Date of Birth": (ctx) => "Date of Birth",
	Email: (ctx) => "Email",
	"Full Name": (ctx) => "Full Name",
	"Share  Amount": (ctx) => "Share  Amount",
	Update: (ctx) => "Update",
	Add: (ctx) => "Add",

	/* Form.CorpsecRegNewCoCombine - step 3 */
	"DIRECTOR DETAILS": (ctx) => "DIRECTOR DETAILS",
	"Note: Nominee Director service is available upon request": (ctx) =>
		"Note: Nominee Director service is available upon request",
	"COMPANY SECRETARY": (ctx) => "COMPANY SECRETARY",
	"Unless you specifically inform us otherwise in writing, we will proceed to appoint our in-house officer as default.":
		(ctx) =>
			"Unless you specifically inform us otherwise in writing, we will proceed to appoint our in-house officer as default.",
	"I understand and agree with the above.": (ctx) => "I understand and agree with the above.",
	"OTHER DIRECTORSHIPS": (ctx) => "OTHER DIRECTORSHIPS",
	"Please specify the names of companies, that any directors hold directorships of.": (ctx) =>
		"Please specify the names of companies, that any directors hold directorships of.",
	"Add Director": (ctx) => "Add Director",
	"I hereby confirm that the above directors had not been the director of not less than 3 companies which were struck off within 5-year period commencing from 3 January 2014 as a consequence of review exercise by the ACRA.":
		(ctx) =>
			"I hereby confirm that the above directors had not been the director of not less than 3 companies which were struck off within 5-year period commencing from 3 January 2014 as a consequence of review exercise by the ACRA.",
	"I hereby confirm that the above directors had not have 3 or more of his companies struck off the register by ACRA under section 344 within a period of 5 years. The 5 year period will commence from after the date on which the third company is struck off.":
		(ctx) =>
			"I hereby confirm that the above directors had not have 3 or more of his companies struck off the register by ACRA under section 344 within a period of 5 years. The 5 year period will commence from after the date on which the third company is struck off.",
	"Enclose clear photocopies of identity cards (both sides) / passports for all Directors/Shareholders together with proof of residential address if passports are furnished.":
		(ctx) =>
			"Enclose clear photocopies of identity cards (both sides) / passports for all Directors/Shareholders together with proof of residential address if passports are furnished.",

	/* Form.CorpsecRegNewCoCombine - step 4 */
	"COMPANY CONSTITUION": (ctx) => "COMPANY CONSTITUION",
	"Companies are incorporated with our standard Constitution, unless otherwise instructed, an additional fee, based on time cost and complexity of job, will be raised for customization of Constitution.":
		(ctx) =>
			"Companies are incorporated with our standard Constitution, unless otherwise instructed, an additional fee, based on time cost and complexity of job, will be raised for customization of Constitution.",
	"I understand and agree with the above.": (ctx) => "I understand and agree with the above.",

	/* Form.CorpsecRegNewCoCombine - step 5 */
	FYE: (ctx) => "FYE",
	" Please decide on your company financial year end": (ctx) => " Please decide on your company financial year end",
	"Deciding on the FYE is very important as it will determine when your corporate filings and taxes are due every year.":
		(ctx) =>
			"Deciding on the FYE is very important as it will determine when your corporate filings and taxes are due every year.",
	"Select Date": (ctx) => "Select Date",

	/* Form.CorpsecRegNewCoCombine - step 6 */
	"DECLARATION BY APPLICANT": (ctx) => "DECLARATION BY APPLICANT",
	"Please enter your Name, NRIC/Passport number and Email": (ctx) =>
		"Please enter your Name, NRIC/Passport number and Email",
	Name: (ctx) => "Name",
	"NRIC / Passport No.": (ctx) => "NRIC / Passport No.",
	Email: (ctx) => "Email",
	I: (ctx) => "I",
	name: (ctx) => "name",
	"of NRIC / Passport No.": (ctx) => "of NRIC / Passport No.",
	email: (ctx) => "email",
	"on behalf of the Board of Directors of the proposed company, hereby declare that:": (ctx) =>
		"on behalf of the Board of Directors of the proposed company, hereby declare that:",
	"the Director(s) is/are not a bankrupt": (ctx) => "the Director(s) is/are not a bankrupt",
	"the member who is a corporation/LLP, is not under liquidation (if applicable)": (ctx) =>
		"the member who is a corporation/LLP, is not under liquidation (if applicable)",
	"the particulars and information including documents, furnished by me in respect of this application form are true and correct to the best of my knowledge and belief, and that I have not willfully suppressed any material fact.":
		(ctx) =>
			"the particulars and information including documents, furnished by me in respect of this application form are true and correct to the best of my knowledge and belief, and that I have not willfully suppressed any material fact.",
	"Please tick checkbox to declare.": (ctx) => "Please tick checkbox to declare.",

	/* miscellaneous */
	"Drag and drop files here": (ctx) => "Drag and drop files here",
	Submit: (ctx) => "Submit",
	Shares: (ctx) => "Shares",
	"Identification Number": (ctx) => "Identification Number",
	"Date of Birth": (ctx) => "Date of Birth",
	DOB: (ctx) => "DOB",
	Mobile: (ctx) => "Mobile",
	Country: (ctx) => "Country",
	"Do you really want to proceed?": (ctx) => "Do you really want to proceed?",
	Nationality: (ctx) => "Nationality",
	"Alternate Address (optional)": (ctx) => "Alternate Address (optional)",
	"Email Address": (ctx) => "Email Address",
	"Other Positions Held": (ctx) => "Other Positions Held",
	"Chief Executive Officer/Managing Director/Secretary (if applicable)": (ctx) =>
		"Chief Executive Officer/Managing Director/Secretary (if applicable)",
	"Add Director": (ctx) => "Add Director",
	Country: (ctx) => "Country",
	Complete: (ctx) => "Complete",
	"Get Link": (ctx) => "Get Link",
	"Save Draft": (ctx) => "Save Draft",
	"Save Email": (ctx) => "Save Email",
	"We will save your progress and send you a link to continue your form application at your convenience.": (ctx) =>
		"We will save your progress and send you a link to continue your form application at your convenience.",
	"Enter your email": (ctx) => "Enter your email",
	'Form submitted successfully': (ctx) => "Form submitted successfully",
	'Thank You': (ctx) => "Thank You",
	'Back to Home': (ctx) => "Back to Home",
	'Submitting': (ctx) => "Submitting",
	'Thank You': (ctx) => "Thank You",
	'Form submitted successfully': (ctx) => "Form submitted successfully",
	'Application Processing Time.': (ctx) => "Application Processing Time.",
	'Processing will typically takes 2-3 business days.': (ctx) => "Processing will typically takes 2-3 business days.",
	"Please correct form error(s) in Section 1.":(ctx) => "Please correct form error(s) in Section 1.",
	"Please correct form error(s) in Section 2.":(ctx) => "Please correct form error(s) in Section 2.",
	"Please correct form error(s) in Section 3.":(ctx) => "Please correct form error(s) in Section 3.",
	"Please correct form error(s) in Section 4.":(ctx) => "Please correct form error(s) in Section 4.",
	"Please correct form error(s) in Section 5.":(ctx) => "Please correct form error(s) in Section 5.",
	"Please correct form error(s) in Section 6.":(ctx) => "Please correct form error(s) in Section 6.",
	
	'Draft saved': (ctx) => "Draft saved",
	'Email sent': (ctx) => "Email sent",

	'Full Name (as in Certificate of Incorporation)': (ctx) => "Full Name (as in Certificate of Incorporation)",
	'Registered Office Address': (ctx) => "Registered Office Address",
	"Date of Incorporation": (ctx)=> "Date of Incorporation",
	"Country of Incorporation": (ctx)=> "Country of Incorporation",
	"Company Registration No.": (ctx)=> "Company Registration No.",
	"Name of Authorised Corporate Representative":(ctx)=>"Name of Authorised Corporate Representative",

	"Enclose clear photocopies of identity cards (both sides) / passports for all Directors/Shareholders together with proof of residential address if passports are furnished. If there are any corporate shareholders, kindly attach certificate of incorporation / ACRA BizFile where relevant.": (ctx)=>"Enclose clear photocopies of identity cards (both sides) / passports for all Directors/Shareholders together with proof of residential address if passports are furnished. If there are any corporate shareholders, kindly attach certificate of incorporation / ACRA BizFile where relevant.",
	registerBusinessTitleWithCountry: `Register your business in {country}`,

	//country
	"Malaysia":"Malaysia",
	"Singapore":"Singapore",





	form: {
		quantity: "Quantity",
		list: "hello, {0}!",
		named: "hello, {name}!",
		linked: "@:message.named How are you?",
		plural: "no bananas | {n} banana | {n} bananas",
	},

	//malaysia
	"Register your business in Malaysia": (ctx)=>"Register your business in Malaysia",
	"QUESTIONNAIRE FOR INCORPORATION OF COMPANY":(ctx) => "QUESTIONNAIRE FOR INCORPORATION OF COMPANY",
	"PRINCIPAL ACTIVITIES":(ctx) => "PRINCIPAL ACTIVITIES",
	"Please furnish the following for registration.":(ctx) => "Please furnish the following for registration.",
	"Please furnish the following for the reservation of name.":(ctx) => "Please furnish the following for the reservation of name.",
	"Please describe your company activity.":(ctx)=>"Please describe your company activity.",
	"BUSINESS ADDRESS": (ctx) => "BUSINESS ADDRESS", // BUSINESS ADDRESS / PRINCIPAL PLACE OF BUSINESS (MUST IN MALAYSIA)
	"Capital Value":(ctx) => "Capital Value",
	"Company name subjects to approval by CCM":(ctx)=>"Company name subjects to approval by CCM",
	"Name (as per NRIC/Passport)":(ctx) => "Name (as per NRIC/Passport)",
	"Gender":(ctx) => "Gender",
	"Shareholding Analysis":(ctx) => "Shareholding Analysis",
	"Shareholder Type":(ctx) => "Shareholder Type",
	"Company Name":(ctx) => "Company Name",
	"Registration No.":(ctx) => "Registration No.",
	"No. of Shares to be taken up":(ctx)=> "No. of Shares to be taken up",
	"Controlled by citizens who are":(ctx)=>"Controlled by citizens who are",
	"Body corporations are controlled by citizens who are":(ctx)=>"Body corporations are controlled by citizens who are",
	"Corporate Representatives":(ctx)=>"Corporate Representatives",
	"the person who represents the shareholder company":(ctx)=>"the person who represents the shareholder company",
	'Number of ordinary shares':(ctx)=>"Number of ordinary shares",
	'Amount per share':(ctx)=>"Amount per share",
	"Service Address (provide if available)":(ctx)=>"Service Address (provide if available)",
	"Designation":(ctx)=>"Designation",
	"Chief Executive Officer/Managing Director/Secretary":(ctx)=>"Chief Executive Officer/Managing Director/Secretary",
	"Particulars of Directorships in other public companies or subsidiaries of public companies:":(ctx)=>"Particulars of Directorships in other public companies or subsidiaries of public companies:",
	"Other Directorships":(ctx)=>"Other Directorships",
	"Please attach copies of the Director identification card / Passport of each Director. Kindly add more if there is more than one director.":(ctx)=>"Please attach copies of the Director identification card / Passport of each Director. Kindly add more if there is more than one director.",
	"SHAREHOLDER DETAILS":(ctx)=>"SHAREHOLDER DETAILS",
	"Name of Public Company":(ctx)=>"Name of Public Company",
	"Date of Appointment":(ctx)=>"Date of Appointment",
	"Please attach a copy of the Identification card / Passport / Certificate of incorporation where relevant. Kindly add more if there is more than one shareholder. If there are any corporate shareholders, kindly attach a copy of Form 9 / Section 17.": (ctx)=>"Please attach a copy of the Identification card / Passport / Certificate of incorporation where relevant. Kindly add more if there is more than one shareholder. If there are any corporate shareholders, kindly attach a copy of Form 9 / Section 17.",
	"I hereby confirm the above is true and correct.":(ctx)=>"I hereby confirm the above is true and correct.",
	"Correspondence":(ctx)=>"Correspondence",
	"PERSON TO CONTACT":(ctx)=>"PERSON TO CONTACT",
	"FINANCIAL YEAR END":(ctx)=>"FINANCIAL YEAR END",
	"EXTRA SERVICES":(ctx)=>"EXTRA SERVICES",
	"i.e. (Tax Agent, Accounting and Auditor)":(ctx)=>"i.e. (Tax Agent, Accounting and Auditor)",
	"PERSONAL DATA PRIVACY":(ctx)=>"PERSONAL DATA PRIVACY",
	"Personal Data Privacy":(ctx)=>"Personal Data Privacy",
	msiaPersonalDataPrivacyP1:(ctx)=>`By engaging our corporate secretarial services, a natural person who is connected to the Company by 
	the nature of his ownership in the Company and/or holding to the office(s) of the Company and/or 
	authorization given by the Board of Directors to represent and act on behalf of the Company, whose 
	identity documents including but not limited to the copies of national registration identity card, 
	international passport, proof of residential address and contact details are required to enable our 
	performance of a range of services in order to carry out the business entrusted to us, consents to the 
	collection, use and disclosure of his personal data (whether such personal data is provided by that 
	member or is collected through a third party) by our company from time to time for any of the 
	following purposes, in accordance with the principles of the Personal Data Protection Act 2010:`,

	msiaPersonalDataPrivacyLi1:(ctx)=>"a) To prepare and compile the corporate documents and statutory records for the Company.",
	msiaPersonalDataPrivacyLi2:(ctx)=>"b) To disclose personal information to government authorities where the disclosure is necessary.",
	msiaPersonalDataPrivacyLi3:(ctx)=>"c) To inform you about our new services that may be of interest to the Company.",
	msiaPersonalDataPrivacyLi4:(ctx)=>"d) To provide information that may be relevant to the Company.",
	msiaPersonalDataPrivacyLi5:(ctx)=>"e) To ensure that the information that we have in our records is accurate and up to date.",

	msiaPersonalDataPrivacyP2: (ctx)=>`The Company warrants that where any of its representative(s) discloses to us the personal data of any 
	natural person who may be connected to the Company after the date of signing of this agreement, he 
	has obtained the prior consent of such natural person before our collection, use and disclosure of his 
	personal data for the aforesaid purposes (“the Warranty”)`,
	"Please tick checkbox to consent.":(ctx)=>"Please tick checkbox to consent.",
	"Place of Incorporation":(ctx)=>"Place of Incorporation",
	"Extra Services":(ctx)=>"Extra Services",
	"Individual":(ctx)=>"Individual",
	"Corporate":(ctx)=>"Corporate",
	'Click here for help with MSIC description': (ctx)=>'Click here for help with MSIC description'

	
	
	
}
