import { defineStore } from 'pinia'
import MainLayout from '@/views/_layout/Layout.Main.vue';
import MainSmallLayout from '@/views/_layout/Layout.Main.Small.vue';
import BlankLayout from '@/views/_layout/Layout.Blank.vue';
import BlankLocaleLayout from '@/views/_layout/Layout.BlankLocale.vue';
import LandingLayout from '@/views/_layout/Layout.Landing.vue';
import BlankLoggedInLayout from '@/views/_layout/Layout.BlankLoggedIn.vue';
import NoneLayout from '@/views/_layout/Layout.None.vue';

import Constant from "@/helpers/Constant";
import Cookies from 'js-cookie'
import Utils from '@/helpers/Utils';
import env from "@/helpers/Environment"
import ShareFunc from "@/helpers/ShareFunc";
import * as siteService from '@/services/service.site';
import _ from 'lodash';
import router from '@/router'
import { useStoreCompany } from "@/stores/store.company"
//import { useStoreSite} from "@/stores/store.site"
import { useStoreForm } from "@/stores/store.form"
// import iconBanking from '@/assets/icons/icon-banking.svg';
import iconBanking from '@/assets/icons/menu/banking.png';
import iconAccounting from '@/assets/icons/icon-accounting.svg';
// import iconCollection from '@/assets/icons/icon-collection.svg';
import iconCollection from '@/assets/icons/menu/collection.png';
// import iconPayroll from '@/assets/icons/icon-payroll.svg';
import iconPayroll from '@/assets/icons/menu/payroll-1.png';
// import iconSalesChannel from '@/assets/icons/icon-sales-channel.svg';
import iconSalesChannel from '@/assets/icons/menu/ARAP.png';
// import iconCorporate from '@/assets/icons/icon-corporate.svg';
import iconCorporate from '@/assets/icons/menu/corporate.png';
// import iconBrochure from '@/assets/icons/icon-brochure.svg';
import iconBrochure from '@/assets/icons/menu/ebrochure.png';
// import iconCrm from '@/assets/icons/icon-crm.svg';
import iconCrm from '@/assets/icons/menu/CRM.png';
// import iconErp from '@/assets/icons/icon-erp.png';
import iconErp from '@/assets/icons/menu/ERP.png';
import iconPaymentLink from '@/assets/icons/icon-payment-link.png';
// import iconPos from '@/assets/icons/icon-pos.png';
import iconPos from '@/assets/icons/menu/POS.png';
import { useI18n } from 'vue-i18n'
import { useLocalStorage } from "@vueuse/core"
import { i18n } from '@/main.js'


const siteMenuItems = [
  {
    label: 'Home',
    icon: `pi pi-home`,
    link: "/dashboard",
    feature: Constant.ENABLED,
  },
  {
    label: 'Profile',
    icon: `pi pi-user`,
    link: "/profile",
    feature: Constant.ENABLED,
  },
  // {
  //   label: 'Access',
  //   icon: `pi pi-users`,
  //   link: "/access",
  //   feature: Constant.ENABLED,
  // },  
  {
    label: 'Security',
    icon: `pi pi-lock`,
    link: "/security",
    feature: Constant.DISABLED,
  },
  {
    label: 'Payment & Subscription',
    icon: `pi pi-credit-card`,
    link: "/payment",
    feature: Constant.DISABLED,
  }
]
const profileMenuItems = [
  {
    label: 'Profile',
    icon: `pi pi-user`,
    link: "/profile",
    feature: Constant.ENABLED,
  },
  {
    label: 'Access',
    icon: `pi pi-users`,
    link: "/access",
    feature: Constant.ENABLED,
  },
  {
    label: 'Sign Out',
    icon: `pi pi-sign-out`,
    func: () => { 			
      Cookies.remove(Constant.COOKIE_WAS_LOGGED_IN)
			location.replace(env.ACCOUNT_SIGN_OUT_URL) 
    },
    feature: Constant.DISABLED,
  },
]
const mbsItems = [
	{
		title: "Corporate Services",
    id: "corpsec",
		description: "Effortlessly manage all your company's corporate affairs here.",
		func: () => {
      const storeCompany = useStoreCompany();
      //console.log("onclick corpsec", storeCompany.selectedCompany);
      if(storeCompany.selectedCompany) {
        window.open(ShareFunc.generateRedirect(env.CORPSEC_FE_COMPANY_ID, storeCompany.selectedCompany.id), "_blank")
      } else {
        const storeSite = useStoreSite();
        storeSite.toggleCorpsecOnboardingSideBar();
      }
      
		},
		img: iconCorporate
	},
	{
		title: "Digital Finance",
    id: "digitalbank",
		description: "Banking at your fingertips with our seamless digital finance services.",
		func: async () => { 

      const storeForm = useStoreForm();
      const storeCompany = useStoreCompany();
      const m = storeCompany.selectedCompany?.activatedModule
      const activated = m?.digitalbank;
      console.log("a.digitalbank", activated)
      

      if(activated) {
        window.open(env.DIGITAL_BANKING_HOME_URL, "_blank")
      } else {
        const storeSite = useStoreSite();
        storeSite.toggleDigitalFinanceOnboardingSideBar();
      }
      /**/
    
    
    },
		img: iconBanking,
    disabled:false,
	},
	{
		title: "Pay & Receive", 
    id: "collection",
		description: "Send and receive globally, scale your business fast.",
		img: iconCollection,
		disabled:true,
	},
]
const vasItems = [
	// {
	// 	title: "SalesOS",
  //   id: "salestools",
	// 	description: "The Super App for sales teams. Set and meet your sales targets.",
	// 	img: iconSalesChannel,
	// 	func: ()=>{ window.open(env.DEMO_LINK_SALES, "_blank") }
	// },	
  {
		title: "AR-AP",
    id: "arap",
		description: "Streamline your accounts payable & receivable workflow.",
		img: iconSalesChannel,
		func: ()=>{ 
      const storeCompany = useStoreCompany();
      if(!storeCompany.companies.length){
        window.open(env.LANDING_DEMO_LINK_ARAP, "_blank")
      }else{
        window.open(env.DEMO_LINK_ARAP, "_blank")
      }
      // window.open(env.DEMO_LINK_ARAP, "_blank") 
    }
	},
  
  {
    title: "Payroll",
    id: "payroll",
    //description: "Streamline your Payroll processes with our efficient and compliant payroll software.",
    description: "Manage leave application, retrieve payslip and more.",
    img: iconPayroll,
    func: ()=>{ 
      const storeCompany = useStoreCompany();
      if(!storeCompany.companies.length){
        window.open(env.LANDING_DEMO_LINK_PAYROLL, "_blank")
      }else{
        window.open(env.DEMO_LINK_PAYROLL, "_blank")
      }
      //window.open(env.DEMO_LINK_PAYROLL, "_blank")
    }
  },
  /*
  {
    title: "POS",
    id: "pos",
    description: "Point of Sales software for easy payment collections.",
    img: iconPos,
    func: ()=>{ 
      const storeCompany = useStoreCompany();
      if(!storeCompany.companies.length){
        window.open(env.LANDING_DEMO_LINK_POS, "_blank")
      }else{
        window.open(env.DEMO_LINK_POS, "_blank")
      }
    }
  },
  // {
  //   title: "CRM",
  //   id: "crm",
  //   description: "Keep track of your leads and convert them with timely follow ups.",
  //   img: iconCrm,
  //   func: ()=>{ 
  //     const storeCompany = useStoreCompany();
  //     if(!storeCompany.companies.length){
  //       window.open(env.LANDING_DEMO_LINK_CRM, "_blank")
  //     }else{
  //       window.open(env.DEMO_LINK_CRM, "_blank")
  //     }
  //     //window.open(env.DEMO_LINK_CRM, "_blank")
  //   }
  // },  


  // {
  //   title: "Ebrochure",
  //   id: "ebrochure",
  //   description: "Send your e-Brochures to your clients for more revenue generation.",
  //   img: iconBrochure,
  //   func: ()=>{ 
  //     const storeCompany = useStoreCompany();
  //     if(!storeCompany.companies.length){
  //       window.open(env.LANDING_DEMO_LINK_EBROCHURE, "_blank")
  //     }else{
  //       window.open(env.DEMO_LINK_EBROCHURE, "_blank")
  //     }
  //     // window.open(env.DEMO_LINK_EBROCHURE, "_blank") 
  //   }
  // },

  {
    title: "E-Brochure",
    id: "equotation",
    description: "Clinch sales on the go. With mobile invoicing and payment features, boost your sales today with MEYZER360 E-brochure module.",
    img: iconBrochure,
    func: ()=>{ 
      const storeCompany = useStoreCompany();
      if(!storeCompany.companies.length){
        window.open(env.LANDING_DEMO_LINK_ERP, "_blank")
      }else{
        window.open(env.DEMO_LINK_EQUOTATION, "_blank")
      }
    }
  },
  {
    title: "SRM",
    id: "srm",
    description: "Deliver greater value through strategic sourcing. Assess suppliers' strengths, performance and capabilities to your overall business strategy.",
    img: iconCrm,
    func: ()=>{ 
      const storeCompany = useStoreCompany();
      if(!storeCompany.companies.length){
        window.open(env.LANDING_DEMO_LINK_ERP, "_blank")
      }else{
        window.open(env.DEMO_LINK_SRM, "_blank")
      }
    }
  },
  {
    title: "ERP",
    id: "erp",
    description: "Improve the productivity of your company with an all-in-one centralised system.",
    img: iconErp,
    func: ()=>{ 
      const storeCompany = useStoreCompany();
      if(!storeCompany.companies.length){
        window.open(env.LANDING_DEMO_LINK_ERP, "_blank")
      }else{
        window.open(env.DEMO_LINK_ERP, "_blank")
      }
      // window.open(env.DEMO_LINK_ERP, "_blank") 
    }
  },

  */

  

]


const objState = {
  init: false,
  apiVersion: { api: null, env: null, stageEnv: null },
  title: 'MEYZER360',
  metadata: [],
  layout: 'blank',
  layoutView: BlankLayout,
  //loginPrompt: false,
  // wasLoggedIn: Cookies.get(Constant.COOKIE_WAS_LOGGED_IN) == "true",
  hadShownWelcome: Cookies.get(Constant.COOKIE_HAD_SHOWN_WELCOME) == "true",
  locale: Cookies.get(Constant.COOKIE_LOCALE),
  siteMenuItems: siteMenuItems,
  profileMenuItems: profileMenuItems,
  mbsItems: mbsItems,
  vasItems: vasItems,

  selectedCompanyProfile: null,

  isMobile: false,
  showNavSideBarFlag: true,
  showSelectCompanyDialogFlag: false,
  showCorpsecOnboardingSideBarFlag: false,
  showDigitalFinanceOnboardingSideBarFlag: false,
  showCreateCompanyProfileDialogFlag: false,

  notificationDialogRef:null,

};


export const useStoreSite = defineStore('site', {

  state: () => _.cloneDeep(objState),
  getters: {
    //isInit: (state) => state.init,
    //getLayout: (state) => state.layoutView,
    wasLoggedIn: (state) => {
      console.log("COOKIE_LOGEDIN ::      ",Cookies.get(Constant.COOKIE_WAS_LOGGED_IN))
      return Cookies.get(Constant.COOKIE_WAS_LOGGED_IN) == "true"
    },
    // hadShownWelcome: (state) => {
    //   return Cookies.get(Constant.COOKIE_HAD_SHOWN_WELCOME) == "true"
    // },
    // getLocale: (state) => {
    //   console.log("locale???", Cookies.get(Constant.COOKIE_LOCALE))
    //   //return Cookies.get(Constant.COOKIE_LOCALE);
    //   return this.locale;
    // }
  },
  actions: {
    reset() {
      this.user = _.cloneDeep(objState)
    },
    async getApiVersion() {
      const res = await siteService.getApiVersion();
      //console.log("res", res)
      this.apiVersion = res.data;
    },
    async initialise() {
      //console.log("initialise", Cookies.get(Constant.COOKIE_LOCALE));
      this.changeLocale(Cookies.get(Constant.COOKIE_LOCALE));
      this.init = true;      
    },
    setTitle(title) {
      this.title = title;
    },
    setMetadata(metadata) {
      this.metadata = metadata;
    },
    setLayout(layout) {
      let v = MainLayout;
      if (layout == 'main') {
        v = MainLayout;
      } else if (layout == 'landing') {
        v = LandingLayout;
      } else if (layout == 'blank') {
        v = BlankLayout;
      } else if (layout == 'blank-locale') {
        v = BlankLocaleLayout;
      } else if (layout == 'blank-logged-in') {
        v = BlankLoggedInLayout;
      } else if (layout == 'none') {
        v = NoneLayout;
      } else if (layout == 'main-small') {
      v = MainSmallLayout;
    }
      this.layout = layout;
      this.layoutView = v;
    },
    // promptLogin(prompt = true) {
    //   //ShareFunc.track("site-prompt-login", "site", "show", prompt);
    //   this.loginPrompt = prompt;
    // },
    checkIsMobile() {
      let flag = false;
      //flag = Utils.isMobile();
      //$break-xx-small: 360px;
      //$break-x-small: 480px;
      //$break-small: 768px;
      const w = window.innerWidth
      if (w <= 768) {
        flag = true;
      }
      this.isMobile = flag;
      //console.log("checkIsMobile", flag)
      return flag;
    },
    checkIsMediumScreen() {
      let flag = false;
      const w = window.innerWidth
      if (w >= 768 && w <= 992) {
        flag = true;
      }
      this.isMobile = flag;
      //console.log("checkIsMediumScreen", w, flag)
      return flag;
    },
    resetFlag(screen) {
      if (screen == "mobile") {
        this.showNavSideBarFlag = false;
        this.showSelectCompanyDialogFlag = false;
        this.showCorpsecOnboardingSideBarFlag = false;
      } else {
        this.showNavSideBarFlag = true;
        this.showSelectCompanyDialogFlag = false;
        this.showCorpsecOnboardingSideBarFlag = false;
      }
    },
    toggleNavSideBar(flag = null) {
      if (flag != null) {
        this.showNavSideBarFlag = flag;;
      } else {
        this.showNavSideBarFlag = !this.showNavSideBarFlag
      }
    },
    toggleSelectCompanyDialog(flag = null) {
      if (flag != null) {
        this.showSelectCompanyDialogFlag = flag;;
      } else {
        this.showSelectCompanyDialogFlag = !this.showSelectCompanyDialogFlag
      }
    },
    toggleCorpsecOnboardingSideBar(flag = null) {
      if (flag != null) {
        this.showCorpsecOnboardingSideBarFlag = flag;
      } else {
        this.showCorpsecOnboardingSideBarFlag = !this.showCorpsecOnboardingSideBarFlag;
      }
    },
    toggleDigitalFinanceOnboardingSideBar(flag = null) {
      if (flag != null) {
        this.showDigitalFinanceOnboardingSideBarFlag = flag;
      } else {
        this.showDigitalFinanceOnboardingSideBarFlag = !this.showDigitalFinanceOnboardingSideBarFlag;
      }
    },
    toggleCreateCompanyProfileDialog(flag = null) {
      if (flag != null) {
        this.showCreateCompanyProfileDialogFlag = flag;
      } else {
        this.showCreateCompanyProfileDialogFlag = !this.showCreateCompanyProfileDialogFlag;
      }
    },      
    setupNotificationDialog(component, hook) {
      this.componentNotification = component;
      this.hookNotification = hook;
    },
    showNotificationDialog() {
      const component = this.componentNotification;
      const dialog = this.hookNotification;
  
      this.notificationDialogRef = dialog.open(component, {
        props: {
          //header: 'Company Access Invitation',
          header: ' ',
          style: { width: '50vw', },
          breakpoints: { '960px': '500px', '640px': '90vw' },
          modal: true,
        },
        onClose: (options) => {

        }
      });
    },
    changeLocale(l="en") {
      Cookies.set(Constant.COOKIE_LOCALE, l)
      this.locale = l;
      // console.log("i18n 1",  Cookies.get(Constant.COOKIE_LOCALE));
      // console.log("i18n 2", this.locale);
      i18n.global.locale.value = l;
    },

    async showToast(summary = "Test", detail = "Test ok", severity = "success", life = 3000) {
      const {app} = await import("@/main");
      app.config.globalProperties.$toast.add({ severity: severity, summary: summary, detail:detail, life:life });
    },

    async showConfirm (acceptFunc, rejectFunc, opts={}) {
      const optsDefault = {header:"Confirmation", message:"Are you sure you want to proceed?", icon:"pi pi-exclamation-triangle"}
      const o = _.extend(optsDefault, opts);
      //console.log("1:", opts, "2: ", o)
      const {app} = await import("@/main");
      app.config.globalProperties.$confirm.require({
          escape: 'true',
          header: o.header,
          message: o.message,
          icon: o.icon,
          accept: async () => { acceptFunc && acceptFunc(); },
          reject: () => { rejectFunc && rejectFunc(); },
      })
    }



  },

})


