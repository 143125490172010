<script setup>
import "vue3-carousel/dist/carousel.css"
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel"
// import imgExploreMore1 from "@/assets/imgs/dashboard/dashboard-exploreMore1.png"

import imgAllInOne from "@/assets/imgs/v2/carousel/all-in-one.webp"
import imgBanking from "@/assets/imgs/v2/carousel/banking.webp"
import imgCompliance from "@/assets/imgs/v2/carousel/compliance.webp"
import imgSpace from "@/assets/imgs/v2/carousel/space.jpg"

const props = defineProps({
	itemsTotal: { type: Number, default: 1 },
	id: { type: String, default: "carousel-2" },
})

const breakpoints = {
	0: { itemsToShow: 1, snapAlign: "start" },
	500: { itemsToShow: 1, snapAlign: "start" },
	600: { itemsToShow: 1, snapAlign: "start" },
	768: { itemsToShow: 1, snapAlign: "start" },
	1080: { itemsToShow: 1, snapAlign: "start" },
	1120: { itemsToShow: 1.3, snapAlign: "start" },
	1280: { itemsToShow: 1.6, snapAlign: "start" },
	1490: { itemsToShow: 2, snapAlign: "start" },
	1600: { itemsToShow: 2.4, snapAlign: "start" },
	// 1700: { itemsToShow: 2.3, snapAlign: "start" },
	// 1800: { itemsToShow: 2.55, snapAlign: "start" },
}

const items = [
	{
		title: "Compliance",
		description: "Manage your governance, accounting and tax compliance online.",
		img: imgCompliance,
		link: "https://www.meyzer360.com/tax-services/",
	},
	{
		title: "All-In-One",
		description:
			"Single sign in with digital identity, gain access to service suite dedicated for your business' success.",
		img: imgAllInOne,
		link: "https://www.meyzer360.com/#",
	},
	{
		title: "Banking",
		description: "Make faster and cheaper payments to over 100+ countries around the world.",
		img: imgBanking,
		link: "https://www.meyzer360.com/banking-global-bank-account/",
	},
	{
		title: "The Future of Business is Here",
		description:
			"Unlock the full potential of your business with our comprehensive suite of services, providing 360-degree management for your company.  Set up your company, business bank accounts, and payment facilities now.",
		img: imgSpace,
		link:"https://www.meyzer360.com/v2/about-us/",
		style:{textContainer:{zIndex:'10'}, title:{color:'white'}, text:{color:'white'}}
	},
]

/* onActions */
const onClickRedirect = (link) => {
	window.open(link, "_blank")
}
</script>

<template>
	<Carousel :id="id" snapAlign="start" :breakpoints="breakpoints" :autoplay="0" mouse-drag>
		<Slide v-for="(item, index) in items" :key="index">
			<div style="position: relative">
				<div class="explore-more-content">
					<div class="card-item-text-container" :style="item.style?.textContainer">
						<h6 class="text-left" :style="item.style?.title">{{ item.title }}</h6>
						<div class="card-text" :style="item.style?.text">
							{{ item.description }}
						</div>
						<div style="text-align: left">
							<!-- <CustomButton style="width: 133px" square>Learn More <i class="ml-1 pi pi-angle-right"></i></CustomButton> -->
							<button :style="item.style?.button"
								@click="onClickRedirect(item.link)"
								class="new-card justify-content-center cursor-pointer align-items-center">
								<span class="button-text">Learn More </span>
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
									<path
										d="M14 15L19 10L14 5"
										stroke="#404040"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round" />
								</svg>
							</button>
						</div>
					</div>

					<div class="card-item-img-container">
						<img :src="item.img" class="max-w-full" :style="item.style?.img"/>
					</div>
				</div>
			</div>
		</Slide>

		<template #addons>
			<Navigation />
		</template>
	</Carousel>
</template>

<style scoped lang="scss">
.new-card {
	border: 0;
	display: flex;
	padding: 8px 16px;
	align-items: center;
	gap: 5px;
	border-radius: 4px;
	background: #fbce07;
	height: 40px;

	@media screen and (max-width: 560px) {
		width: 100%;
	}
}
.explore-more-content {
	display: flex;
	width: 545px;
	height: 201px;
	border: 1px solid #d9d9d9;
	background-color: #fffdf8;
	border-radius: 4px;

	@media screen and (min-width: $break-xx-small) and (max-width: 560px) {
		flex-direction: column;
		width: 100%;
		height: 318px;
		row-gap: 14px;
	}
	@media screen and (max-width: 359px) {
		flex-direction: column;
		width:100%;
		// width: 288px;
		height: 318px;
		row-gap: 14px;
	}
	.card-item-text-container {
		border-radius: 4px;
		padding-top: 1rem;
		padding-bottom: 1rem;
		padding-left: 24px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		// row-gap: 1rem;
		padding-right: 12px;
	}
	.card-item-img-container {
		margin-top: -65px;
		background: #fdf5da;
		border-radius: 50% 0;
		width: 479.14px;
		height: 434px;
		@media screen and (max-width: 560px) {
			border-top-left-radius: 50%;
			border-top-right-radius: 50%;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			margin-top: 0;
			padding-top: 12px;
			width: 100%;
			height: 451px;
			margin-right: -90px;
		}
	}
	.card-item-img-container img {
		position: absolute;
		right: 0;
		bottom: 0;
		@media screen and (max-width: 560px) {
			left: 50%;
			transform:translateX(-50%);
			
		}
	}
}
.card-text {
	color: #404040;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20.8px;
	text-align: left;
}
</style>
