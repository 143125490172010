<template>
  <!-- {{ dropdownValue }} -->
  <Dropdown filter class="dropdown-custom w-full" v-model="model"  :showClear="false" 
    :options="countriesData" optionLabel="name" optionValue="name" 
    placeholder="Select Country" >

    <template #value="slotProps">
      <label class="custom-float-label"> {{ t('Country') }} </label>
      <!-- <div class="dropdown-items-wrapper-custom" v-if="dropdownValue">
        <flag class="flag mr-2" :iso="dropdownValue.iso" />
        <div class="country-code">{{dropdownValue.dialCode}}</div>
      </div>
      <div v-else>
        +65
      </div> -->
      <div class="flex" v-if="dropdownValue">
        <flag class="flag selected-flag mr-3" style="flex-shrink: 0;" :iso="dropdownValue.code.toLowerCase()" />
        <div class="country-code">{{dropdownValue.name}}</div>
      </div>

      <div v-else>
        Singapore
      </div>
    </template>

    <template #option="slotProps">
      <!-- {{  slotProps }} -->
      <div class="dropdown-items-wrapper-custom" >
        <div class="flex" >
        <!-- <div class="flex" v-if="storeSite.isMobile"></div> -->
          <flag :iso="slotProps.option.code.toLowerCase()" class="flag mr-3"/>
          <div class="country mr-2">{{ slotProps.option.name }}</div>
          <!-- <div class=" mr-2">(+{{slotProps.option.phone}})</div> -->
        </div>
      </div>
    </template>
</Dropdown>

<!-- {{ model }} -->

<!-- <flag iso="it" />
<flag iso="gb" />
<flag iso="us" /> -->

<!-- 
LOYNOTE

yarn add vue-flag-icon
ref: https://www.npmjs.com/package/vue-flag-icon
-->


</template>

<script setup>
import _ from "lodash";
import Dropdown from 'primevue/dropdown';
// import InputText from 'primevue/inputtext';
import { computed, onMounted } from 'vue'
import { countriesData } from '@/assets/json/all-country.js';
import { useStoreSite } from '@/stores/store.site'

import { useI18n } from 'vue-i18n';
import {addTranslation, getAllTranslation} from '@/intl/generate.js';

const { t:tt, locale } = useI18n();
//locale.value = 'cn';
const t = (txt) => {
	addTranslation(txt);
	return tt(txt)
}


const storeSite = useStoreSite();

const props = defineProps({ 
      modelValue: { type: [String, Number], default: ''},
      pLabel:  { type: [String, Number], default: ''}
  })

const emit = defineEmits(['update:modelValue'])

const model = computed({
  get () { return props.modelValue },
  set (value) { return emit('update:modelValue', value) }
})

const dropdownValue = computed (()=>{
  const code = model.value;
  const res = _.find(countriesData, {name: code});
  return res;
})

onMounted(()=>{
  if(!model.value) model.value = "Singapore";
})
</script>

<style scoped lang="scss" >
  .selected-flag{
    font-weight: 400;
    font-size: 1.1rem;
    top:0.5em;
    position: relative;
  }

  .country-code {
    font-weight: 400;
    font-size: 1.1rem;
    top:0.45em;
    position: relative;
  }

  .custom-float-label {
    color: #6c757d;
    top: 0.45em;
    left: 1.0em;
    font-size: 0.8em;
    position: absolute;
  }
  
  .dropdown-custom{
    border: 1px solid transparent;
    border-radius: 5px;
    border-bottom: 1px solid #ECECEC;
    background-color: #f5fbff;
    min-height: 58px;
    align-items: center;
  }

.dropdown-items-wrapper-custom {
  max-width:50vw;
}

  // .p-dropdown .p-dropdown-trigger {
  //   width: auto
  // }
</style> 
