//ref: 
//https://gist.github.com/arifmahmudrana/b991f1c15162654b3a481780884c0d4d
//https://github.com/hapijs/joi/issues/2539
//https://joi.dev/api/?v=17.9.1#anyvalidatevalue-options

export const joiIntlErrors = {
	en: {
		'any.invalid': '{{#label}} contains an invalid value',
		'array.min': '{{#label}} must contain at least {{#limit}} items',
		'number.base': '{{#label}} must be a number',
		'number.positive': '{{#label}} must be a positive number',
		'string.email': '{{#label}} must be a valid email',
		'string.empty': '{{#label}} is not allowed to be empty',
	},
	cn: {
		'any.invalid': '{{#label}} 包含无效值',
		'array.min': '{{#label}} 必须至少包含 {{#limit}} 项目',
		'number.base': '{{#label}} 必须是一个数字',
		'number.positive': '{{#label}} 必须是正数',
		'string.email': '{{#label}} 必须是有效的电子邮件',
		'string.empty': '{{#label}} 不允许为空',
	},
  }