import { callApi, } from '@/services/WebApi';

//api examples.
// const createNewCompany = (data) => {
//   return callApi('POST', `companies`, data)
// };

export const getCompanies = () => {
  return callApi('GET', `companies`)
};

// const updateCompany = (reqId, data) => {
//   return callApi('PUT', `request/new-company/`+reqId+`/reply`, data)
// };

export const getCompanyWithPermission = () => {
  return callApi('GET', `permissions`)
};

export const getCompanyRequests = (companyId) => {
  return callApi('GET',`company/${companyId}/request` )
}

export const getCompanyMilestones = (companyId) => {
  return callApi('GET',`company/${companyId}/timeline` )
}

export const getDocumentById = (companyId, documentId) => {
  return callApi('GET',`company/${companyId}/document/${documentId}` )
}

export const getDigibankBalById = (companyId) => {
  return callApi('GET',`company/${companyId}/wallet` )
}

export const createCompanyLite = (data) => {
  return callApi('POST', `company`, data)
}

export const activateCompanyModule = (companyId, data) => {
  return callApi('PUT', `company/${companyId}/activate`, data)
}
// export const companyService = {
//   // createNewCompany,
//   getCompanies,
//   // updateCompany,
//   getCompanyWithPermission,
// };