<script setup>
import { computed } from "vue"

const props = defineProps({
	disabled: { type: Boolean, default: false },
	advertisement: {type:Boolean, default:false}
})

const cardClass = computed(() => ({
	disabled: props.disabled,
}))
</script>

<template>
	<div class="card-custom" :class="[{'ad' : props.advertisement}]">
			<slot name="content"></slot>
	</div>
</template>

<style scoped lang="scss">
.card-custom {
	// height: 100%;
	border-radius: 0.5rem;
	border: 1px solid #d3d6e9;
	background: #fff;
	padding:1rem;
	box-shadow: 0px 2px 4px 0px rgba(96, 97, 112, 0.16), 0px 0px 1px 0px rgba(40, 41, 61, 0.04);
	transition: all 0.2s ease;

	&:hover{
		// background: #f0f0f0;
	}

	
	// &:hover:after {
	// 	content: '';
	// 	display: block;
	// 	position: absolute;
	// 	left: 0;
	// 	top: 0;
	// 	width: 100%;
	// 	height: 100%;
	// 	opacity: 0.15;
	// 	background-color:white;
	// }

	&.ad {
		background-image: url('@/assets/imgs/adSME_bg.png');
	}
}
</style>
