<template>
    <div class="logo-holder">
      <a href="/">
        <i class="ico logo-m360-white logo"></i>
      </a>
    </div>
  </template>
  
  <script setup>
  
  import { onMounted } from 'vue';
  
  
  </script>
  
  <style scoped lang="scss">

    .logo-holder .logo {
      width: 140px;
      height: 45px;
    }
  </style>