<template>
	<footer>
		<div class="footer-section-left">
			<div class="logo-holder hidden md:block">
				<i class="ico logo logo-mz-4"></i>
			</div>
		</div>
		<div class="footer-section-right">
			<div class="version-service-policy text-right">
				<div class="mr-2">v{{ version }} {{ stageEnv }}</div>
				| &nbsp;
				<div class="mr-2"><a href="https://www.meyzer360.com/terms-conditions/" target="_blank">Terms of Service</a></div>
				| &nbsp;
				<div class="policy"><a href="https://www.meyzer360.com/privacy-policy/" target="_blank">Privacy Policy</a></div>
				<span class="line-next-to-policy">| &nbsp;</span>
			</div>
			<div class="text-right">&copy; {{ new Date().getUTCFullYear() }} MEYZER360 All Right Reserved.</div>
		</div>
	</footer>
</template>

<script setup>
//pinia store
import { useStoreSite } from "@/stores/store.site"

//others
import env from "@/helpers/Environment"

const storeSite = useStoreSite()
const version = __APP_VERSION__
const stageEnv = env.STAGE_ENV == "prod" ? "" : env.STAGE_ENV
const apiVersion = storeSite.apiVersion
</script>

<style scoped lang="scss">
footer {
	position: relative;
	display: flex;
	justify-content: space-between;
	font-size: 0.8rem;
	color: #333333;
	padding: 8px;
  	background-color: white;

	.footer-section-left {
		.logo-holder {
			position: absolute;
			top: 9px;
			.logo {
				width: 60.57px;
				height: 10.96px;
			}
		}
	}
	.footer-section-right {
		.version-service-policy {
			display: flex;
			.line-next-to-policy {
				display: none;
			}
		}
	}
}

@media screen and (min-width: $break-small) {
	footer {
		// color: $font-footer-color-dark;
		.footer-section-right {
			display: flex;
			.version-service-policy {
				.policy {
					margin-right: 0.5rem;
				}
				.line-next-to-policy {
				}
			}
		}
	}
}
</style>
