<script setup>
import { ref, reactive, computed, watch, onMounted, inject, nextTick } from "vue"
import Carousel01 from "@/views/Dashboard.Carousel01.vue"
import Carousel02 from "@/views/Dashboard.Carousel02.vue"
import Carousel03 from "@/views/Dashboard.Carousel03.vue"
import imgAnnouncement from '@/assets/imgs/welcome/announcement.png'

const rData = reactive({
	loading: false,
	activeIndex: 0,
	featurePreviewTitle: "MEYZER360 features preview",
	videoSrc: 'https://www.youtube.com/embed/r1DT9uwkNI0?si=ydeLvEA2GTxa7vmy',
});

const dialogRef = inject('dialogRef');

onMounted(async () => {
	await nextTick();
	if (dialogRef.value.data?.showFintechVideo) {
		rData.activeIndex = 3
		rData.featurePreviewTitle = "MEYZER360 Digital Finance features";
		rData.videoSrc = "https://www.youtube.com/embed/r1DT9uwkNI0?si=ydeLvEA2GTxa7vmy&amp;start=109"
		//rData.videoSrc = "https://youtu.be/r1DT9uwkNI0?t=108"
		
	}
	
})


</script>

<template>

		<div class="holder mt-0">

			<TabView v-model:activeIndex="rData.activeIndex">
				<TabPanel header="Announcements">
					<div class="mt-3">
						<h1 class="text-center">Available Q2 2024</h1>
						<div class="">
							<img :src="imgAnnouncement" class="w-full h-29rem" style="object-fit: contain;" />
						</div>
					</div>
				</TabPanel>
				<TabPanel header="Business Entity">
					<Carousel01 />
				</TabPanel>
				<TabPanel header="Digital Finance Account">
					<Carousel02 />
				</TabPanel>
				<TabPanel header="Global Payment">
					<Carousel03 />
				</TabPanel>
				<TabPanel header="Features Preview">
					<div class="mt-2 mb-3">{{rData.featurePreviewTitle}}</div>
					<div class="vid-holder">
						<iframe width="100%" height="100%" :src="rData.videoSrc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowfullscreen></iframe>
					</div>
					

					<div class="mt-3">
						Experience an easy & professional setup with MEYZER360.
					</div>


				</TabPanel>
				
			</TabView>
                
		</div>
		

</template>

<style scoped lang="scss">

.holder {

	// width: 100%;
	// height: 100%;
	
	max-width: 700px;
	max-height: 700px;

	.vid-holder {
		width: 700px;
		height: 412px;
	}


	@media screen and (max-width: $break-small) {
		div { margin-top: 3}
		min-height: 55vh;
		.vid-holder {
			margin-top: 4rem;
			width:100%;
			height:100%;
		}
	}
}


</style>
