<script setup>
import Carousel from 'primevue/carousel';

import ad1 from "@/assets/imgs/welcome/step01-1.png"
import ad2 from "@/assets/imgs/welcome/step02-1.png"
import ad3 from "@/assets/imgs/welcome/step04.png"

import { useStoreSite } from '@/stores/store.site';

const storeSite = useStoreSite()

const adCopy1 = "Click 'Business Entity' to seamlessly initiate the process of company incorporation or transfer-in. Elevate your business journey with MEYZER360 – where efficiency meets innovation.";
const adCopy2 = "Click 'Register Your New Company' to embark on the exciting path of establishing your own company. Alternatively, select 'Transfer to MEYZER360' to effortlessly appoint MEYZER360 as your trusted company secretary.";
const adCopy3 = "Enter your preferred company names and dive into the next steps of company incorporation with MEYZER. Unleash the full suite of services and take your enterprise to new heights. Start now and watch your business thrive with MEYZER360's comprehensive support!"

const ads = [{ img: ad1, copy:adCopy1 }, { img: ad2, copy:adCopy2 }, { img: ad3, copy:adCopy3 }]

</script>

<template>

<Carousel :value="ads" :numVisible="1">
    <template #item="{data}">
			<div class="surface-100 border-1 surface-border border-round mt-3 p-3 ">
				<Image :src="data.img" imageClass="w-full" :preview="storeSite.isMobile" />
			</div>
			<div class="text-left mt-4">{{data.copy}}</div>
    </template>
</Carousel>

</template>

<style scoped lang="scss">
.carousel__item {
	width:100%;
}

.carousel__slide {
	padding: 8px;
}

</style>
