<template>
	<!-- <div class="doc" style="padding: 20px;" size="A4"> -->
				<router-view v-slot="{ Component }">
					<keep-alive><component :is="Component" /></keep-alive>
				</router-view>
	<!-- </div> -->
</template>

<script setup>
	import { reactive, toRef, ref, onMounted, onBeforeMount, onBeforeUnmount, nextTick, toRaw, computed, onUpdated } from "vue"

</script>

<style lang="scss" scoped>
// .site-wrapper {
// 	display: flex;
// 	flex-direction: column;
// 	min-height: 100%;
// 	background-color: #f4f7ff;
// 	nav {
// 		display: flex;
// 		align-items: center;
// 		justify-content: space-between;
// 		height: 50px;
// 		//padding: 0 27px;
// 		padding: 0 0 0 27px;
// 		background-color: white;
// 	}
// }
// .content-wrapper {
// 	flex: 1;
// 	width: 87.18%;
// 	max-width: 1029px;
// 	margin: 0 auto;
// 	min-height: calc(100vh - 85px);
// }


body {
  background: rgb(204,204,204); 
}


</style>
